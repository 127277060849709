
import {defineComponent} from "vue"
import {useI18n} from "vue-i18n"

export default defineComponent({
  name: "CompensationPackages",
  setup() {
    const {t, locale} = useI18n({
      inheritLocale: true,
      useScope: "global",
    })
    return {t, locale}
  },
})
