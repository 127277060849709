{
  co2Calculator: "CO<sub>2</sub>&nbsp;Rechner",
  introduction: "Mit Fragen zu 5 Faktoren kannst du ganz schnell Deinen persönlichen Fußabdruck in CO<sub>2</sub> Äquivalenten (CO<sub>2</sub>e) abschätzen. Dein Fußabdruck wird interaktiv für sowohl jeden Faktor, als auch insgesamt berechnet. Die Quellen zu jedem Faktor sind angegeben und der Quellcode ist auf <a href='https://github.com/JarnoRFB/planted-co2-calculator'>GitHub</a> verfügbar. <br /> Alle Optionen sind auf einen großen Fußabdruck voreingestellt. Finde heraus wie viel CO<sub>2</sub> Du bereits sparst und wo noch Verbesserungspotenziale bestehen. Viel Spaß!",
  tonsCarbon: "Tonnen CO<sub>2</sub>e",
  result: "Mit einem Fußabdruck von {totalEmissions} liegst Du {relationToAverage} dem deutschen Durchschnitt von 11,17 Tonnen. Um deinen Fußabdruck zu kompensieren empfehlen wir dir das <strong>{recommendedPackage}</strong> Paket. Wähle dein Paket gleich hier 🖣 </a>",
  relation: {
    farBelow: "weit unter",
    below: "unter",
    above: "über",
    farAbove: "weit über",
  },
  sources: "Quellen",
  value: "Wert",
  thisSourceIsValid: "Diese Quelle ist auf dem neusten Stand.",
  thisSourceIsInvalid: "Diese Quelle ist nicht mehr auf dem neusten Stand.",
  shortHauls: "Kurzstreckenflüge",
  mediumHauls: "Mittelstreckenflüge",
  longHauls: "Langstreckenflüge",
  kilometersPerWeek: "Kilometer in der Woche",
  householdSize: "Personen im Haushalt",
  apartmentSize: "Wohnfläche",
  apartmentAge: "Baujahr",
  energySource: "Energiequelle",
  greenEnergy: "Ökostrom",
  flying: {
    question: "Wie oft bist Du in den letzten zwölf Monaten geflogen?",
  },
  driving: {
    question: "Wie viele Kilometer fährst Du pro Woche mit dem Auto?",
  },
  housing: {
    question: "Heizung und Strom",
    type: "Art des Haushalts",
    options: {
      oil: "Heizöl",
      naturalGas: "Gas",
      longDistanceHeating: "Fernwärme",
      heatingPump: "Heizpumpe",
      woodPellets: "Holzpellets",
      house: "Haus",
      apartment: "Wohnung",
    },
  },
  nutrition: {
    question: "Wie ernährst Du Dich?",
    options: {
      CARNIVORE: "fleischreich",
      FLEXITARIAN: "flexitarisch",
      VEGETARIAN: "vegetarisch",
      VEGAN: "vegan",
    },
  },
  consumerism: {
    question: "Wie schätzt Du Deinen Konsum ein?",
    options: {
      frugal: "sparsam",
      normal: "normal",
      lush: "hoch",
    },
  },
}
